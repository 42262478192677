import { template as template_79354bb76c444f36baf44ef3a74f57de } from "@ember/template-compiler";
const FKLabel = template_79354bb76c444f36baf44ef3a74f57de(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
