import { template as template_cba5447b5c304a888ffd554d489bff11 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_cba5447b5c304a888ffd554d489bff11(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
